export function sortOptionsVariants(options) {
  const sizeProduct = [
    'XXS',
    'XS',
    'S',
    'M',
    'L',
    'XL',
    '2XL',
    'XXL',
    '3XL',
    'XXXL'
  ]

  return [...options].sort((a, b) => {
    const valueA = a?.value?.toString()
    const valueB = b?.value?.toString()

    const indexA = sizeProduct.indexOf(valueA)
    const indexB = sizeProduct.indexOf(valueB)

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB
    }

    const numA = parseFloat(valueA)
    const numB = parseFloat(valueB)

    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB
    }

    if (!isNaN(numA)) {
      return -1
    }
    if (!isNaN(numB)) {
      return 1
    }

    return valueA.localeCompare(valueB)
  })
}
